import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div style={{height:"100vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
      Coming Soon...!
    </div>
  );
}

export default App;
